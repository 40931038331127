import PageHeader from '@/components/PageHeader.vue'
import Adsearch from '@/components/Adsearch'
import tableMixin from '@/minxin/table-minxin'
import { regionData, CodeToText } from 'element-china-area-data' // CodeToText
import root from '@/minxin/root'
const Index = {
  name: 'orderCustomers',
  components: {
    PageHeader,
    Adsearch
  },
  mixins: [tableMixin, root],
  data() {
    return {
      root_name: 'dkxd',
      root_msg: '代客下单',
      AddressType: 10,
      showSetAddress: false,
      ruleForm1: {
        IDX: '',
        TakeSelf: '', // 是否自提-0:否 1:是,
        Province: '',
        City: '',
        Area: '',
        Street: '',
        Address: '',
        DeptName: '',
        ContactName: '',
        ContactTel: '',
        Filepath: '',
        AddrType: 10 // 地址类型-10 收货地址 20 收票地址
      },
      Province: [],
      City: [],
      Area: [],
      form: {
        KeyWords: '',
        BuyCompanyID: '',
        SendCompanyID: '',
        ContractID: '',
        BrandID: '',
        MaterialTypeID: '',
        MaterialSubTypeID: ''
      },
      createForm: {
        ExpectedDeliveryDate: '',
        OrderType: 0,
        ExpiredDate: '',
        LotNoRequire: '',
        isKe: 0,
        StockCode: '',
        SimsSectionID: '',
        SimsSectionName: '',
        address: '',
        card: '',
        Remark: '',
        OrderAddressID: '', // 收货ID
        InvoiceSendID: '' // 寄票地址ID
      },
      formSearch: {
        KeyWords: ''
      },
      buys: [], // 买方
      terminalList: [], // 终端
      contractList: [], // 合约
      brandList: [], // 品牌
      parentList: [], // 类型
      childrenList: [], // 子类型
      activeNames: '1',
      showCarView: false,
      tableData1: [],
      Bottom: '0',
      selects: [],
      showCreate: false,
      showAddress: false,
      isAddressLoading: false,
      DivisionDeptList: [], // 科室
      DateList: [], // 效期
      addressList: [], // 地址
      selectsTemp: [],
      rules1: {
        Province: [
          { required: true, message: '请输入省', trigger: 'blur' }
        ],
        City: [
          { required: true, message: '请输入市', trigger: 'blur' }
        ],
        Area: [
          { required: true, message: '请输入区', trigger: 'blur' }
        ],
        Address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        ContactName: [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
        ContactTel: [
          { required: true, message: '请输入电话', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.GetBuyCompanyList()
    // this.GetSendCompanyList()
    this.GetProductContractList()
    regionData.map(item => {
      this.Province.push({
        label: item.label,
        value: item.value
      })
    })
    // this.GetBrandList()
  },
  watch: {
    selectsTemp: {
      handler(a) {
        console.log(a)
        for (let i = 0; i < a.length; i++) {
          for (let j = i + 1; j <= a.length - 1; j++) {
            if (a[i].MaterialRefCompanyID === a[j].MaterialRefCompanyID) {
              // eslint-disable-next-line no-self-assign
              this.selectsTemp[i].Num = this.selectsTemp[i].Num
              return this.selectsTemp.splice(j, 1)
            }
          }
        }
      },
      deep: true
    },
    showCreate(a) {
      if (!a) return this.$refs['createForm'].resetFields()
    },
    selects(a) {
      console.log(a)
      // eslint-disable-next-line no-empty
      // this.tableData.forEach(item => {
      //   const selected = a.length && a.indexOf(item) !== -1
      //   if (selected) {
      //     // 选中
      //     this.$set(item, 'selectED', true)
      //     this.$refs.dataTable.toggleRowSelection(item, true)
      //   } else {
      //     this.$set(item, 'selectED', false)
      //     this.$refs.dataTable.toggleRowSelection(item, false)
      //   }
      // })
    }
  },
  computed: {
    allNum() {
      return this.selectsTemp.length
    },
    nums() {
      let num = 0
      this.selectsTemp.forEach(item => {
        num += item.Num
      })
      return num
    },
    price() {
      let num = 0
      this.selectsTemp.forEach(item => {
        num += item.Num * item.ContactPrice
      })
      return num.toFixed(3)
    }
  },
  methods: {
    // 清空
    clearAll(itemKey) {
      for (const key in this.form) {
        this.form[key] = ''
      }
      console.log('this.form', this.form)
      this.getTableDataAsync()
    },
    Adsearch() {
    },
    // 复制到页面上实现获取数据逻辑
    async getTableDataAsync(page, pageSize) {
      this.form.PageIndex = page || 1
      this.form.PageSize = this.tablePageSize
      const response = await this.$api.GetProductList(this.form)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      console.log(response)
      this.tableData.map(item => {
        this.$nextTick(() => {
          this.$set(item, 'Num', 1)
          this.selectsTemp.forEach(item1 => {
            if (item.MaterialRefCompanyID === item1.MaterialRefCompanyID) {
              this.$refs.dataTable.toggleRowSelection(item, true)
            }
          })
        })
      })
      if (!response.Message) response.Message = []
      return response
    },
    // 查询买方
    GetBuyCompanyList() {
      this.$api.GetBuyCompanyList(this.form).then(res => {
        this.buys = res.Message
      })
    },
    // 查询终端列表
    GetSendCompanyList() {
      this.$api.GetSendCompanyList(this.form).then(res => {
        this.terminalList = res.Message
      })
    },
    // 查询合约列表
    GetProductContractList() {
      this.$api.GetProductContractList(this.form).then(res => {
        console.log(res)
        this.contractList = res.Message
      })
    },
    // 查看品牌
    GetBrandList() {
      this.$api.GetBrandList(this.form).then(res => {
        console.log(res)
        this.brandList = res.Message
      })
    },
    // 查看类型
    GetMaterialTypeList() {
      this.$api.GetMaterialTypeList(this.form).then(res => {
        console.log('类型', res)
        if (this.form.MaterialTypeID) {
          return this.childrenList = res.Message
        }
        this.parentList = res.Message
      })
    },
    // 查询科室列表
    GetCompanyDepartmentList() {
      this.$api.GetCompanyDepartmentList({
        CompanyID: this.form.BuyCompanyID
      }).then(res => {
        console.log(res)
        this.DivisionDeptList = res.Message
      })
    },
    // 期效要求查询
    QueryExpiredDateList() {
      this.$api.QueryExpiredDateList().then(res => {
        this.DateList = res.Message
      })
    },
    // 买方变更
    buyChange(e, isClose) {
      if (isClose) this.form.BuyCompanyID = ''
      this.form.SendCompanyID = ''
      this.form.ContractID = ''
      this.form.BrandID = ''
      this.form.MaterialTypeID = ''
      this.form.MaterialSubTypeID = ''
      this.GetSendCompanyList() // 获取终端数据
      this.getTableDataAsync()
      this.viewCarLocalStrange() // 查询购物车
    },
    //  终端变更
    sendChange(e, isClose) {
      if (isClose) this.form.SendCompanyID = ''
      this.form.ContractID = ''
      this.form.BrandID = ''
      this.form.MaterialTypeID = ''
      this.form.MaterialSubTypeID = ''
      this.GetProductContractList() // 获取合约数据
      this.getTableDataAsync()
      // this.clearAll('ContractID')
      this.viewCarLocalStrange() // 查询购物车
    },
    // 合约变更
    contractChange(e, isClose) {
      if (isClose) this.form.ContractID = ''
      this.form.BrandID = ''
      this.form.MaterialTypeID = ''
      this.form.MaterialSubTypeID = ''
      this.GetBrandList() // 获取品牌数据
      this.getTableDataAsync()
      // this.clearAll('BrandID')
    },
    // 品牌变更
    brandChange(e, isClose) {
      if (isClose) this.form.BrandID = ''
      this.form.MaterialTypeID = ''
      this.form.MaterialSubTypeID = ''
      this.GetMaterialTypeList() // 获取类型数据
      this.getTableDataAsync()
      // this.clearAll('MaterialTypeID') //
    },
    // 类型变更
    typeChange(e, isClose) {
      if (isClose) this.form.MaterialTypeID = ''
      this.form.MaterialSubTypeID = ''
      this.GetMaterialTypeList() // 获取类型
      this.getTableDataAsync()
      // this.clearAll('MaterialSubTypeID')
    },
    // 子类型变更
    typeChildChange(e, isClose) {
      if (isClose) this.form.MaterialSubTypeID = ''
      this.getTableDataAsync()
    },
    selectAll(e) {
      console.log(e) // MaterialLotNo
      // if (e.length > 0) {
      //   // 选中
      //   e.map(item => {
      //     this.$set(item, 'selectED', true)
      //   })
      // } else {
      //   e.map(item => {
      //     this.$set(item, 'selectED', false)
      //   })
      // }
      this.selects = e
    },
    // 删除
    delItem(index, item) {
      console.log(index, item)
      this.tableData.map(item1 => {
        if (item1.IDX === item.IDX) {
          this.$refs.dataTable.toggleRowSelection(item1, false)
        }
      })

      this.selects.splice(index, 1)
      this.selectsTemp.splice(index, 1)
      this.delLocalStrangeItem()
    },
    select(selection, row) {
      // const selected = selection.length && selection.indexOf(row) !== -1
      // if (selected) {
      //   // 选中
      //   this.$set(row, 'selectED', true)
      // } else {
      //   this.$set(row, 'selectED', false)
      // }
      // this.selectsTemp = isBtn
      // if (!isBtn) return
      this.selects = selection
      // this.setCarLocalStrange()
    },
    // 科室改变
    SimsSection(e) {
      console.log(e)
      const item = this.DivisionDeptList.filter(item => item.IDX === e)
      this.createForm.SimsSectionName = item[0].GroupName
    },
    // 添加选中
    addGoods() {
      // this.selects = []
      // this.tableData.map(item => {
      //   if (item.selectED) {
      //     this.selects.push(item)
      //   }
      // })
      if (!this.form.SendCompanyID || !this.form.BuyCompanyID) return this.$message.info('请选择买方和终端')
      if (this.selects.length <= 0) return this.$message.info('请勾选所需加入购物车物料')
      const arr = this.selects.filter(item => !item.Num)
      if (arr.length > 0) return this.$message.info('加入失败，请检查数量')
      console.log(this.selects)
      this.selectsTemp = this.selects.concat(...this.selectsTemp)
      this.setCarLocalStrange()
      this.tableData.forEach(item => {
        this.$refs.dataTable.toggleRowSelection(item, false)
      })
      return this.$message.success('加入成功')
    },
    // 单个添加
    addGood(row) {
      // this.selectsTemp = true
      if (!this.form.SendCompanyID || !this.form.BuyCompanyID) return this.$message.error('请选择买方和终端')
      // if (row.selectED) return this.$message.error('已选中')
      if (!row.Num) return this.$message.error('加入失败，请检查数量')
      // this.$set(row, 'selectED', true)
      this.$refs.dataTable.toggleRowSelection(row, true)
      // this.setCarLocalStrange()
      this.selectsTemp.push(row)
      // this.selects = this.selects
      this.setCarLocalStrange()
      this.tableData.forEach(item => {
        this.$refs.dataTable.toggleRowSelection(item, false)
      })
      return this.$message.success('加入成功')
      // this.select([row], row, true)
    },
    // 新增地址/ 编辑
    submitAddress(formName) {
      this.ruleForm1.Province = CodeToText[this.ruleForm1.Province[0]]
      this.ruleForm1.City = CodeToText[this.ruleForm1.City[0]]
      this.ruleForm1.Area = CodeToText[this.ruleForm1.Area[0]]
      this.ruleForm1.CompanyID = this.form.BuyCompanyID
      // if (this.form.isKe === 0) {
      //   this.ruleForm1.CompanyID = 0
      // }
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        this.ruleForm1.TakeSelf ? this.ruleForm1.TakeSelf = 1 : this.ruleForm1.TakeSelf = 0
        // 保存地址
        this.$api.SaveUserAddress(this.ruleForm1).then(res => {
          console.log(res)
          this.showSetAddress = false
          if (res.RetCode === '0') {
            this.$refs[formName].resetFields()
            this.addressShow(this.AddressType)
            this.ruleForm1.CompanyID = ''
            return this.$message.success('保存成功')
          }
          this.ruleForm1.CompanyID = ''
          return this.$message.error('保存失败')
        })
      })
    },
    handleFileChange(e) {
      const _this = this
      const file = e.target.files[0]
      const size = Math.floor(file.size / 1024 / 1024)
      if (size > 5) {
        return this.$message({
          message: '图片大小不能超过5M',
          type: 'warning'
        })
      }
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        _this.$api.OtherInStockUploadAccessory({
          FileExtName: '.' + file.type.split('/')[1],
          Base64String: e.target.result
        }).then(res => {
          _this.ruleForm1.Filepath = res.Message
        })
      }
    },
    handleChange(value) {
      console.log(value[0])
      this.City = []
      const City = []
      Object.assign(City, regionData.filter(item => item.value === value[0]))
      City[0].children.map(item => {
        this.City.push({
          label: item.label,
          value: item.value
        })
      })
      // this.ruleForm1.Province = CodeToText[value[0]]

      // console.log(this.City)
    },
    handleChange1(value) {
      this.Area = []
      const City = regionData.filter(item => item.value === this.ruleForm1.Province[0])
      console.log(City)
      const Area = City[0].children.filter(item => item.value === value[0])
      console.log(Area)
      if (Area[0].children) {
        Area[0].children.map(item => {
          this.Area.push({
            label: item.label,
            value: item.value
          })
        })
      }
      // this.ruleForm1.City = CodeToText[value[0]]
    },
    handleChange2(value) {
      // this.ruleForm1.Area = CodeToText[value[0]]
    },
    // 查看购物车
    viewCars() {
      // const key_ = 'J' + this.form.BuyCompanyID + 'R' + this.form.SendCompanyID
      // if (!key_) return this.$message.error('请选择买方和终端')
      // if (!localStorage.getItem('JR_Car')) { localStorage.setItem('JR_Car', JSON.stringify({})) }
      // const data = JSON.parse(localStorage.getItem('JR_Car'))
      // if (!data[key_]) {
      //   this.showCarView = false
      //   return this.$message.error('请先加入购物车')
      // }
      this.showCarView = !this.showCarView
      // if (this.selects.length <= 0) return this.$message.error('没有任何选中')
      // const arr = this.selects.filter(item => !item.Num)
      // if (arr.length > 0) return this.$message.error('加入失败，请检查数量')
      // this.showCarView = !this.showCarView
      //
    },
    // 同上次批号
    letNumChange(w) {
      console.log(w, 'this.selects', this.selectsTemp)
      if (w) {
        let InvCodes = ''
        this.selectsTemp.forEach(item => {
          console.log(item)
          console.log(item.InvCode)
          InvCodes += `${item.InvCode},`
        })
        console.log('InvCodes', InvCodes)
        this.$api.GetHistoryLotNum({
          CompanyID: this.form.SendCompanyID,
          InvCodes: InvCodes
        }).then(res => {
          console.log(res)
          if (res.Message !== null && res.Message.length > 0) {
            this.selects.forEach(item => {
              res.Message.forEach(item2 => {
                if (item.InvCode === item2.InvCode) {
                  this.$set(item, 'ExpiredDateShow', item2.ExpiredDateShow)
                  return this.$set(item, 'LotNum', item2.LotNum)
                }
              })
            })
            this.selectsTemp.forEach(item => {
              res.Message.forEach(item2 => {
                if (item.InvCode === item2.InvCode) {
                  this.$set(item, 'ExpiredDateShow', item2.ExpiredDateShow)
                  return this.$set(item, 'LotNum', item2.LotNum)
                }
              })
            })
          }
        })
        return
      }
      this.selects.map(item => {
        this.$nextTick(() => {
          this.$set(item, 'LotNum', '')
        })
      })
      this.selectsTemp.map(item => {
        this.$nextTick(() => {
          this.$set(item, 'LotNum', '')
        })
      })
    },
    // 选择
    slectAddressInfo(item) {
      console.log(item)
      if (this.AddressType === 10) {
        this.createForm.address = item.Province + item.City + item.Area + item.Street + item.Address + item.ContactName + item.ContactTel
        this.createForm.OrderAddressID = item.IDX
      }
      if (this.AddressType === 20) {
        this.createForm.card = item.Province + item.City + item.Area + item.Street + item.Address + item.ContactName + item.ContactTel
        this.createForm.InvoiceSendID = item.IDX
      }
      this.showAddress = false
    },
    // 收货地址 选择
    addressShow(type) {
      this.showAddress = true
      this.AddressType = type
      this.ruleForm1.AddrType = type
      console.log(this.createForm.isKe)
      if (type === 20) {
        this.$api.GetUseraddressList({
          CompanyID: this.form.BuyCompanyID,
          AddrType: type
        }).then(res => {
          this.addressList = res.Message
        })
        return
      }
      if (type === 10) {
        this.$api.GetUseraddressList({
          CompanyID: this.form.BuyCompanyID, // this.createForm.isKe === 1 ? this.form.SendCompanyID :
          AddrType: type
        }).then(res => {
          this.addressList = res.Message
        })
      }
    },
    // 创建订单
    createOrder() {
      console.log(this.selects)
      this.GetCompanyDepartmentList()
      this.QueryExpiredDateList()
      if (this.selectsTemp.length <= 0) return this.$message.error('购物车数据为空')
      this.showCreate = true
      this.delLocalStrangeItem()
    },
    isExpiredDate(a) {
      console.log(a)
      const arr = this.DateList.filter(item => item.value === a)[0]
      console.log(arr.label)
      this.createForm.Remark += ` ${arr.label} `
    },
    // 提交 创建订单
    submit() {
      if (!this.createForm.OrderAddressID) return this.$message.error('收货地址为必填')
      this.createForm.OrderJson = JSON.stringify(this.selectsTemp)
      this.createForm.SendCompanyID = this.form.SendCompanyID
      // this.createForm.SendCompanyID = this.form.SendCompanyID
      console.log(this.createForm)
      this.$api.CreateOrder(this.createForm).then(res => {
        console.log(res)
        if (res.RetCode === '0') {
          this.showCreate = false
          this.selects = []
          this.selectsTemp = []
          this.$message.success('创建成功')
          this.delLocalStrangeItem()
          this.tableData.map(item1 => {
            this.$refs.dataTable.toggleRowSelection(item1, false)
          })
          return
        }
        return this.$message.error('创建失败' + res.RetMsg)
      })
    },
    // 查看本地是否有购物车数据
    viewCarLocalStrange() {
      if (!localStorage.getItem('JR_Car')) return false
      const _key = 'J' + this.form.BuyCompanyID + 'R' + this.form.SendCompanyID
      const data = JSON.parse(localStorage.getItem('JR_Car'))
      if (data[_key] && data[_key].length > 0) {
        // this.selects = data[_key]
        return this.selectsTemp = data[_key]
      }
      return this.selectsTemp = []
    },
    // 删除本地该项缓存
    delLocalStrangeItem() {
      const _key = 'J' + this.form.BuyCompanyID + 'R' + this.form.SendCompanyID
      const data = JSON.parse(localStorage.getItem('JR_Car'))
      data[_key] = this.selectsTemp // 购物车数据 重新复制
      console.log(this.selectsTemp)
      if (data[_key].length <= 0) {
        delete data[_key]
      }
      localStorage.setItem('JR_Car', JSON.stringify(data))
    },
    // 存入本地
    setCarLocalStrange() {
      // 先查询本地缓存是否存在买方ID和终端ID的名称
      // 存在则更新该项数据
      // 不存在则创建一条数据放到JR_Car
      //  BuyCompanyID: '',
      //  SendCompanyID: '',
      if (!localStorage.getItem('JR_Car')) { localStorage.setItem('JR_Car', JSON.stringify({})) }
      const data = JSON.parse(localStorage.getItem('JR_Car'))
      const Key_ = 'J' + this.form.BuyCompanyID + 'R' + this.form.SendCompanyID
      const _data = this.selectsTemp // 购物车数据
      data[Key_] = _data
      localStorage.setItem('JR_Car', JSON.stringify(data))
    }
  }
}
export default Index
