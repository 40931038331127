<template>
  <div class="orderCustomers p-lr-20 p-tb-20">
    <div class="top_view">
      <div class="item flex j-between a-center min-border-bottom p-left-10 p-right-5">
        <div class="f12 weight">筛选条件：</div>
        <div class="f12 weight flex j-start a-center" style="flex:1;">
          <el-tag
            v-if="buys.filter(item =>item.id === form.BuyCompanyID)[0]"
            closable
            type="success"
            @close="buyChange($event,true)"
          >
            {{ buys.filter(item =>item.id === form.BuyCompanyID)[0].text }}
          </el-tag>
          <div class="p-right-5" />
          <el-tag
            v-if="terminalList.filter(item =>item.id === form.SendCompanyID)[0]"
            closable
            type="info"
            @close="sendChange($event,true)"
          >
            {{ terminalList.filter(item =>item.id === form.SendCompanyID)[0].text }}
          </el-tag>
          <div class="p-right-5" />
          <el-tag
            v-if="contractList.filter(item =>item.id === form.ContractID)[0]"
            closable
            type="warning"
            @close="contractChange($event,true)"
          >
            {{ contractList.filter(item =>item.id === form.ContractID)[0].text }}
          </el-tag>
          <div class="p-right-5" />
          <el-tag
            v-if="brandList.filter(item =>item.id === form.BrandID)[0]"
            closable
            type="success"
            @close="brandChange($event,true)"
          >
            {{ brandList.filter(item =>item.id === form.BrandID)[0].text }}
          </el-tag>
          <div class="p-right-5" />
          <el-tag
            v-if="parentList.filter(item =>item.id === form.MaterialTypeID)[0]"
            closable
            type="danger"
            @close="typeChange($event,true)"
          >
            {{ parentList.filter(item =>item.id === form.MaterialTypeID)[0].text }}
          </el-tag>
          <div class="p-right-5" />
          <el-tag
            v-if="childrenList.filter(item =>item.id === form.MaterialSubTypeID)[0]"
            closable
            type="info"
            @close="typeChildChange($event,true)"
          >
            {{ childrenList.filter(item =>item.id === form.MaterialSubTypeID)[0].text }}
          </el-tag>
        </div>
        <div>
          <el-link type="primary" :underline="false" @click="clearAll">清空</el-link>
        </div>
      </div>
      <div class="item flex j-between a-center weight shaixuan">
        <div class="flex a-center">
          <div class="f12 weight">买方</div>
          <el-select v-model="form.BuyCompanyID" clearable filterable placeholder="请选择" size="mini" @change="buyChange">
            <el-option
              v-for="item in buys"
              :key="item.id"
              :label="item.text"
              :value="item.id"
            />
          </el-select>
        </div>
        <div class="flex a-center">
          <div class="f12 weight">终端</div>
          <el-select v-model="form.SendCompanyID" clearable filterable placeholder="请选择" size="mini" @change="sendChange">
            <el-option
              v-for="item in terminalList"
              :key="item.id"
              :label="item.text"
              :value="item.id"
            />
          </el-select>
        </div>
        <div class="flex a-center">
          <div class="f12 weight">合约</div>
          <el-select v-model="form.ContractID" clearable filterable placeholder="请选择" size="mini" @change="contractChange">
            <el-option
              v-for="item in contractList"
              :key="item.id"
              :label="item.text"
              :value="item.id"
            />
          </el-select>
        </div>
        <div class="flex a-center">
          <div class="f12 weight" style="width:35px">品牌</div>
          <el-select v-model="form.BrandID" clearable filterable placeholder="请选择" style="width:100%" size="mini" @change="brandChange">
            <el-option
              v-for="item in brandList"
              :key="item.id"
              :label="item.text"
              :value="item.id"
            />
          </el-select>
        </div>
        <div class="flex a-center">
          <div class="f12 weight" style="width:35px">类型</div>
          <el-select v-model="form.MaterialTypeID" clearable filterable placeholder="请选择" style="width:100%" size="mini" @change="typeChange">
            <el-option
              v-for="item in parentList"
              :key="item.id"
              :label="item.text"
              :value="item.id"
            />
          </el-select>
        </div>
        <div class="flex a-center">
          <div class="f12 weight" style="width:35px">子类</div>
          <el-select v-model="form.MaterialSubTypeID" clearable filterable placeholder="请选择" style="width:100%" size="mini" @change="typeChildChange">
            <el-option
              v-for="item in childrenList"
              :key="item.id"
              :label="item.text"
              :value="item.id"
            />
          </el-select>
        </div>
      </div>

    </div>
    <div class="m-tb-10 m-bottom-40">
      <div class="searchView flex a-center m-tb-10 j-between">
        <div class="flex SearchBox_30" style="flex: 1">
          <el-input
            v-model="form.KeyWords"
            size="medium"
            placeholder="可通过物料编号、物料名称、规格进行搜索"
            class="rule-input-edit"
          >
            <!-- <template slot="prepend">
              <div class="pointer" @click="Adsearch">
                <i class="el-icon-s-operation" />
                <span>高级搜索</span>
              </div>
            </template> -->
            <template slot="prepend">
              <i class="el-icon-search" />
            </template>
            <div
              slot="append"
              class="cursor"
              @click="searchGetTableDataAsync"
            >
              搜 索
            </div>
          </el-input>
        </div>
        <div class="ModelBttton-white-30 m-left-10 el-button--primary" @click="addGoods">
          <i class="el-icon-folder-add p-right-10" />
          <span>加入购物车</span>
        </div>
      </div>
      <el-table
        ref="dataTable"
        v-loading="tableLoading"
        :header-cell-style="headerCStyle"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        height="calc(100vh - 410px)"
        border
        @select-all="selectAll"
        @select="select"
      >
        <el-table-column fixed="left" type="selection" width="40" />
        <el-table-column prop="InvCode" label="物料编号" width="100" />
        <el-table-column prop="ManuPartNum" label="原厂编号" width="100" />
        <el-table-column prop="InvName" label="物料名称" min-width="200" />
        <el-table-column prop="Specification" label="规格" min-width="200" />
        <el-table-column prop="StockUnit" label="单位" width="60" />
        <el-table-column prop="BrandName" label="品牌" width="200" />
        <el-table-column prop="ContactPrice" label="价格" width="80" />
        <el-table-column prop="PQ1" label="近一月采购量" width="120" />
        <!-- <el-table-column prop="InvCode" label="剩余备货" /> -->
        <el-table-column prop="Num" label="数量" width="80" fixed="right">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.Num" style="width:80px" :controls="false" size="mini" :min="1" />
            <!-- <el-input v-model.number="scope.row.Num" type="number" :min="1" size="mini" /> -->
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="50"
        >
          <template slot-scope="scope">
            <!-- phalange 20210608 如果物料已经禁用则不让购买 -->
            <el-link v-if="scope.row.MRF_DelFlag === 0" :undeline="false" type="primary" @click="addGood(scope.row)">添加</el-link>
            <span v-else style="color:red">已冻结</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 购物车  start -->
    <div class="cart_view" :style="{bottom: Bottom}">
      <div v-if="form.SendCompanyID && form.BuyCompanyID">
        <div class="title_view flex j-between a-center p-lr-20">
          <div class="flex a-center">
            <p class="p-right-30">已为收货方公司添加<span class="theme-color p-lr-5 weight">{{ allNum }}</span>种物料，共计<span class="theme-color p-lr-5 weight ">{{ nums ? nums : 0 }}</span>件，总计<span class="theme-color p-lr-5 weight ">{{ price ? price : 0 }}</span>元</p>
            <el-button class="ModelBttton1-blue-30" type="primary" @click="createOrder">创建订单</el-button>
          </div>
          <div class="flex a-center">
            <el-button size="mini" @click="addGoods">加入购物车</el-button>
            <el-button size="mini" @click="viewCars">{{ showCarView ? '收起' :'查看' }}
              <i class="el-icon-arrow-down" />
            </el-button>
          </div>
        </div>
        <div v-if="showCarView">
          <el-table
            v-if="selectsTemp"
            :header-cell-style="headerCStyle"
            :data="selectsTemp"
            height="300"
            tooltip-effect="dark"
            show-header
            border
          >
            <!-- <el-table-column fixed="left" type="selection" /> -->
            <af-table-column prop="InvCode" label="物料编号" align="center" />
            <af-table-column prop="ManuPartNum" label="原厂编号" align="center" />
            <af-table-column prop="InvName" label="物料名称" align="center" />
            <af-table-column prop="Specification" label="规格" align="center" />
            <af-table-column prop="StockUnit" label="单位" align="center" />
            <af-table-column prop="BrandName" label="品牌" align="center" />
            <af-table-column prop="ContactPrice" label="价格" align="center" />
            <af-table-column prop="PQ1" label="近一月采购量" align="center" />
            <!-- <el-table-column prop="InvCode" label="剩余备货" /> -->
            <el-table-column prop="Num" label="数量" align="center">
              <template slot-scope="scope">
                <el-input-number v-model="scope.row.Num" style="width:80px" :controls="false" size="mini" :min="1" />
                <!-- <el-input v-model.number="scope.row.Num" type="number" :min="1" size="mini" /> -->
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="100"
            >
              <template slot-scope="scope">
                <el-link :undeline="false" type="primary" @click="delItem(scope.$index,scope.row)">删除</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div v-else class="flex j-end a-center p-tb-20 p-lr-20">
        未选择收货方，请先选择收货方
      </div>
    </div>
    <!-- 购物车  end -->
    <!-- 代客下单 start -->
    <el-dialog class="DepartmentBox" top="5vh" :close-on-click-modal="false" :visible.sync="showCreate" width="800px!important" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">代客下单</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => showCreate = false" />
      </div>
      <el-form ref="createForm" class="ValetList" :model="createForm" :inline="true" label-width="110px">
        <el-form-item label="期望到货日期" prop="ExpectedDeliveryDate">
          <el-date-picker v-model="createForm.ExpectedDeliveryDate" style="width:100%" type="date" size="mini" placeholder="选择日期" value-format="yyyy-MM-dd" />
        </el-form-item>
        <el-form-item label="效期要求" prop="ExpiredDate">
          <el-select v-model="createForm.ExpiredDate" placeholder="请选择" size="mini">
            <el-option v-for="item in DateList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="批号要求" class="p-right-20" prop="LotNoRequire">
          <el-checkbox v-model="createForm.LotNoRequire" @change="letNumChange">同上次批号</el-checkbox>
        </el-form-item>
        <!-- <el-form-item label="是否直接送客户" prop="isKe">
            <el-radio-group v-model="createForm.isKe">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item> -->
        <!-- <el-form-item label="入库仓位">
            <el-select v-model="createForm.StockCode" placeholder="请选择" size="mini">
              <el-option
                v-for="item in []"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item> -->
        <el-form-item label="科室" prop="SimsSectionID">
          <el-select v-model="createForm.SimsSectionID" filterable placeholder="请选择" size="mini" @change="SimsSection">
            <el-option v-for="item in DivisionDeptList" :key="item.IDX" :label="item.GroupName" :value="item.IDX" />
          </el-select>
        </el-form-item>
        <el-form-item label="收货地址" class="flex" prop="address" :rules="[{ required: createForm.isKe !== 2 , message: '收货地址不能为空'}]">
          <div class="flex j-between a-center">
            <el-input v-model="createForm.address" disabled size="mini" />
            <div class="m-left-10" />
            <el-button size="mini" @click="addressShow(10)">选择</el-button>
          </div>
        </el-form-item>
        <el-form-item label="收票地址" prop="card">
          <div class="flex j-between a-center">
            <el-input v-model="createForm.card" disabled size="mini" />
            <div class="m-left-10" />
            <el-button size="mini" @click="addressShow(20)">选择</el-button>
          </div>
        </el-form-item>
        <div class="aa">
          <el-form-item label="订单备注" prop="Remark">
            <el-input v-model="createForm.Remark" size="mini" />
          </el-form-item>
        </div>
      </el-form>
      <el-table ref="dataTable2" :header-cell-style="headerCStyle" :data="selectsTemp" tooltip-effect="dark" show-header height="300" border>
        <el-table-column prop="InvCode" label="物料编号" show-overflow-tooltip />
        <el-table-column prop="ManuPartNum" label="原厂编号" show-overflow-tooltip />
        <el-table-column prop="InvName" label="物料名称" show-overflow-tooltip :width="200" />
        <el-table-column prop="Specification" label="规格" show-overflow-tooltip />
        <el-table-column prop="StockUnit" label="单位" show-overflow-tooltip />
        <el-table-column prop="BrandName" label="品牌" show-overflow-tooltip />
        <!-- <el-table-column prop="BrandName" label="原数量" /> -->
        <el-table-column prop="Num" label="采购数量" show-overflow-tooltip />
        <el-table-column prop="LotNum" label="批号" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input v-model="scope.row.LotNum" disabled />
          </template>
        </el-table-column>
        <el-table-column prop="ExpiredDate" label="效期">
          <template slot-scope="scope">
            <el-input v-model="scope.row.ExpiredDateShow" disabled />
            <!-- <el-date-picker
              v-model="scope.row.ExpiredDate"
              type="date"
              size="mini"
              style="width:50px"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            /> -->
          </template>
        </el-table-column>
        <el-table-column prop="Remark" label="添加备注" fixed="right">
          <template slot-scope="scope">
            <el-input v-model="scope.row.Remark" />
          </template>
        </el-table-column>

      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
      <div class="caozuo m-tb-20 line p-top-20">
        <div class="tongji">已为收货方公司添加<span class="theme-color p-lr-5 weight">{{ allNum }}</span>种物料，共计<span class="theme-color p-lr-5 weight ">{{ nums ? nums : 0 }}</span>件，总计<span class="theme-color p-lr-5 weight ">{{ price ? price : 0 }}</span>元</div>
        <el-button @click="() => showCreate = false">关闭</el-button>
        <el-button type="primary" @click="submit">提交</el-button>
      </div>
    </el-dialog>
    <!-- 代客下单 end -->
    <!-- 选择订单地址 start -->
    <el-dialog class="DepartmentBox" :close-on-click-modal="false" :visible.sync="showAddress" custom-class="dialog_view1" :show-close="false" width="600px">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">{{ AddressType === 20 ? '选择收票地址' :'选择收货地址' }} </span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => showAddress = false" />
      </div>
      <div class="m-tb-10 t-right">
        <el-button class="MoedelBotton-blue-sm" @click="() => showSetAddress = true">新建地址</el-button>
      </div>
      <!-- 地址 start-->
      <div v-if="addressList.length" v-loading="isAddressLoading" class="address_view_set flex column p-bottom-20">
        <div v-for="(item,index) in addressList" :key="index" style="width:100%;box-sizing: border-box" class="item flex a-center j-between p-left-10">
          <div style="flex:1">
            {{ item.Province + item.City + item.Area + `${item.Street ? item.Street :''} `+ item.Address + item.ContactName + item.ContactTel }}
          </div>
          <div style="min-width:50px" class="flex">
            <el-button class="ModelBttton1-white-24" @click="slectAddressInfo(item)">选择</el-button>
          </div>
        </div>
      </div>
      <div v-else class="m-bottom-20">
        暂无地址数据
      </div>
      <!-- 地址 end-->
    </el-dialog>
    <!-- 选择订单地址 end -->
    <!-- 新建地址 start -->
    <el-dialog class="DepartmentBox" :close-on-click-modal="false" :visible.sync="showSetAddress" custom-class="dialog_view" :show-close="false" width="400px!important">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">新建地址</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => showSetAddress = false" />
      </div>
      <div class="m-top-20" />
      <el-form ref="ruleForm1" :model="ruleForm1" :rules="rules1" label-width="80px" class="demo-ruleForm">
        <el-form-item label="上门自提" prop="name">
          <el-checkbox v-model="ruleForm1.TakeSelf">备选项</el-checkbox>
        </el-form-item>
        <el-form-item label="省" prop="Province">
          <el-cascader
            v-model="ruleForm1.Province"
            size="mini"
            :options="Province"
            style="width:100%"
            @change="handleChange"
          />
          <!-- <el-input v-model="ruleForm1.Province" size="mini" /> -->
        </el-form-item>
        <el-form-item label="市" prop="City">
          <el-cascader
            v-model="ruleForm1.City"
            size="mini"
            :options="City"
            style="width:100%"
            @change="handleChange1"
          />
          <!-- <el-input v-model="ruleForm1.City" size="mini" /> -->
        </el-form-item>
        <el-form-item label="区" prop="Area">
          <el-cascader
            v-model="ruleForm1.Area"
            size="mini"
            :options="Area"
            style="width:100%"
            @click="handleChange2"
          />
          <!-- <el-input v-model="ruleForm1.Area" size="mini" /> -->
        </el-form-item>
        <!-- <el-form-item label="街道" prop="Street">
          <el-input v-model="ruleForm1.Street" size="mini" />
        </el-form-item> -->
        <el-form-item label="地址" prop="Address">
          <el-input v-model="ruleForm1.Address" size="mini" />
        </el-form-item>
        <el-form-item label="收货部门" prop="DeptName">
          <el-input v-model="ruleForm1.DeptName" size="mini" />
        </el-form-item>
        <el-form-item label="联系人" prop="ContactName">
          <el-input v-model="ruleForm1.ContactName" size="mini" />
        </el-form-item>
        <el-form-item label="联系电话" prop="ContactTel">
          <el-input v-model="ruleForm1.ContactTel" size="mini" />
        </el-form-item>
        <el-form-item label="上传附件" prop="Filepath">
          <input id="file" type="file" name="file" multiple="multiple" @change="handleFileChange">
        </el-form-item>
        <el-form-item class="caozuo p-tb-20 t_line_s">
          <el-button @click="() => showSetAddress = false">取消</el-button>
          <el-button type="primary" @click="submitAddress('ruleForm1')">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 新建地址 end-->
  </div>
</template>

<script>
import Index from '@/minxin/orderMananger/orderCustomers.js'
export default Index
</script>
<style lang="scss" scoped>
@import "@/style/search.scss";
@import "@/style/purchase.scss";
/* .dialog_view{
    position: relative !important;
} */
@media  only screen and (max-width: 1400px) {
  .dialog_view {
    width: 90% !important;
    position: relative !important;
  }
}
@media only screen and ( max-width: 1000px) {
  .dialog_view {
    width: 90% !important;
  }
}
.top_view{
  width: 100%;
  height: auto;
  border: 1px solid #dcdfe6;
  .item{
    min-height: 30px;
  }
}
.top_view .item .el-link{ border: 1px #eee solid; font-size: 12px; padding: 5px 5px; line-height: 100%;}
.top_view .item span.el-tag{ line-height: 120%; padding: 2px 10px; height:auto;}

.shaixuan{ width:100%;padding: 10px 10px 10px 10px; display: inline-block; height:auto!important; box-sizing: border-box;}
.shaixuan .flex{ display: inline-block;  width: calc(100% / 6); padding: 0 10px 0 0; box-sizing: border-box;}
.shaixuan .flex:last-child{padding-right:0}
.shaixuan .flex .f12{ line-height: 100%; margin-bottom: 8px;}

.shaixuan .flex .el-select{ width: 100%;}

.main_view{
  width: 100%;
  height: auto;
  border: 1px solid #dcdfe6;
}
.cart_view{
  width: calc(100% - 180px);
  height:auto;
  position: absolute;
  right: 0;
  z-index: 99;
  background-color: #fff;
  box-shadow: 0 -3px 5px rgba(0,0,0, .1);
}
</style>
